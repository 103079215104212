var site = site || {};
site.spp = site.spp || {};
site.spp.data = site.spp.data || {};
var POWERREVIEWS = POWERREVIEWS || {};
(function($) {
  var pr_products = [];
  var drupalPRSettings = Drupal.settings.analytics ? Drupal.settings.analytics.power_reviews_enh_enabled : false;
  // Set up PowerReviews data on product.init
  $(document).on('product.init-pr', function() {
    pr_products = [];
    var pr_locale = document.location.href.search('fr.clinique.ca') > 0 && typeof Drupal.settings.power_reviews.locale_fr !== 'undefined' ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
    var pr_merchant_id = document.location.href.search('fr.clinique.ca') > 0 && typeof Drupal.settings.power_reviews.merchant_id_fr !== 'undefined' ? Drupal.settings.power_reviews.merchant_id_fr : Drupal.settings.power_reviews.merchant_id;

    if (POWERREVIEWS && typeof Drupal.settings.power_reviews !== 'undefined') {
      var mpp_snippet_counter = 1;
      $('.js-product-pr').each(function() {
        if ($(this).data('productid') !== '' && typeof $(this).data('productid') !== 'undefined') {
          var product_id = $(this).data('productid');
          var product_base_id = product_id.substring(4);
          var reviewsnippet_id = 'pr-reviewsnippet-' + product_base_id + '-' + mpp_snippet_counter + '-' + (new Date()).getTime();
          var $reviewsnippet = $(this).hasClass('mpp-product') ? $('.mpp_product .reviews', this) : $(this).hasClass('product') ? $('.reviews', this) : $('.mpp-product .reviews', this);
          var pr_product = null;
          $reviewsnippet.attr('id', reviewsnippet_id);
          var page_id = Drupal.settings.power_reviews.page_id || product_base_id;
          var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?pr_page_id=' + page_id;
          if ($reviewsnippet.length) {
            if (product_base_id && !$reviewsnippet.hasClass('reviews-processed')) {
              $reviewsnippet.addClass('reviews-processed');
              pr_product = {
                api_key: Drupal.settings.power_reviews.api_key,
                locale: pr_locale,
                merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
                merchant_id: pr_merchant_id,
                page_id: page_id,
                review_wrapper_url: review_wrapper_url,
                components: {
                  ReviewSnippet: reviewsnippet_id,
                }
              };
              if (site.client.isMobile && (typeof $('.module-spp-detail').data('productid') !== 'undefined' || typeof $('.spp-product').data('productid') !== 'undefined')) {
                pr_product.on_read_reviews_click = function() {
                  $(document).trigger('anchor-review');
                  $('.js-pr-rating-tab', $('.responsive-tabs--powerreviews')).trigger('click');
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    site.track.reviewRead();
                  }
                };
                pr_product.on_write_review_click = function(config, data) {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    site.track.reviewWriteStart();
                  }
                  var war_link = $('.pr-snippet-write-review-link').attr('href');
                  if (typeof war_link !== 'undefined' && war_link !== '') {
                    window.location.href = war_link;
                  }
                };
              }
            }
            //for Hover
            if ($(this).parent().find('.mpp-product-hover-view').length) {
              var $reviewhover = $('.mpp-product-hover-view .reviews', this);
              $reviewhover.attr('id', reviewsnippet_id + 'hover');
              var hover_id = reviewsnippet_id + 'hover';
              pr_product.components.CategorySnippet = hover_id;
            }
            pr_products.push(pr_product);
          }
          mpp_snippet_counter++;
        }
      });
      //For SPP Page
      if (typeof $('.module-spp-detail').data('productid') !== 'undefined' || typeof $('.spp-product').data('productid') !== 'undefined') {
        var product_id = $('.module-spp-detail').data('productid') || $('.spp-product').data('productid');
        var product_base_id = product_id.substring(4);
        var page_id = Drupal.settings.power_reviews.page_id || product_base_id;
        var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?pr_page_id=' + page_id;
        var pr_product = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: pr_locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: pr_merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          REVIEW_DISPLAY_SNAPSHOT_TYPE: 'SIMPLE',
          components: {
            ReviewDisplay: 'pr-review-display'
          }
        };
        if ($('#pr-spp-snippet').length) {
          pr_product.components.ReviewSnippet = 'pr-spp-snippet';
        }
        pr_product.on_read_reviews_click = function() {
          $(document).trigger('anchor-review');
          $('.js-pr-rating-tab', $('.responsive-tabs--powerreviews')).trigger('click');
          if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
            site.track.reviewRead();
          }
        };
        pr_product.on_write_review_click = function(config, data) {
          if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
            site.track.reviewWriteStart();
          }
          var war_link = $('.pr-snippet-write-review-link').attr('href');
          if (typeof war_link !== 'undefined' && war_link !== '') {
            window.location.href = war_link;
          }
        };
        var product = typeof site.spp.data.product !== 'undefined' ? site.spp.data.product : '';
        if (product !== '') {
          pr_product.on_render = function(config, data) {
            if (config.component === 'ReviewDisplay') {
              if (!$('#pr-review-snapshot').hasClass('pr-snapshot-mobile')) {
                $('.pr-rd-review-header-sorts').insertBefore('.pr-rd-main-header-search');
              } else {
                $('.pr-review-snapshot-block-histogram', $('.pr-review-snapshot-simple')).append($('.pr-snippet-write-review-link', $('.pr-review-snapshot-block-snippet')));
              }
              $('.pr-review-snapshot-block-recommend').insertBefore('.pr-review-snapshot-block-snippet');
            }
            powerReviewsAnalyticsTagging();
          };
          var image_url = typeof product.IMAGE_SPP === 'object' ? product.IMAGE_SPP[0] : product.IMAGE_SPP;
          var url = encodeURI(product.url);
          var base_url = window.location.protocol + '//' + document.location.hostname;

          pr_product.strip_html_tags = function(str) {
            if ((str === null) || (str === '')) {
              return false;
            } else {
              str = str.toString();
              return str.replace(/<[^>]*>/g, '');
            }
          };

          pr_product.product = {
            name: pr_product.strip_html_tags(product.PROD_RGN_NAME),
            url: base_url + url,
            image_url: base_url + image_url,
            description: pr_product.strip_html_tags(product.DESCRIPTION),
            category_name: product.DEFAULT_CAT_ID,
            upc: product.skus[0].UPC_CODE,
            brand_name: 'Clinique',
            price: product.skus[0].PRICE,
            in_stock: (!!product.isShoppable).toString()
          };
        } else {
          pr_product.on_render = function(config, data) {
            powerReviewsAnalyticsTagging();
          };
        }
        pr_products.push(pr_product);
      }
      if (Drupal.settings.power_reviews.show_ask_question && ($('.module-spp-detail').length && $('.js-product').length) && !$('#pr-questionsnippet').hasClass('question-processed')) {
        $('#pr-questionsnippet').addClass('question-processed');
        $('.js-pr-ask-tab').removeClass('hidden');

        var pr_product_question = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: pr_locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: Drupal.settings.power_reviews.merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_render: function(config, data) {
            var question_count = data.question_count;
            if (question_count > 0) {
              $('.pr-ask-question-link').addClass('hidden');
              if (!$('.pr-snippet-qa-answer-link').hasClass('count-rendered')) {
                $('.pr-snippet-qa-answer-link').append(' ' + question_count);
                $('.pr-snippet-qa-answer-link').addClass('count-rendered');
              }
            } else {
              $('.pr-snippet-qa-metrics').addClass('hidden');
            }
          },
          on_view_answers_click: function() {
            $('.js-pr-ask-tab').trigger('click');
            $('html, body').animate({
              'scrollTop': $('.tabs-nav').offset().top
            }, 1000);
          },
          on_ask_question_click: function() {
            $('.js-pr-ask-tab').trigger('click');
            var headerHeight = $('#top_wrapper').height();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top - headerHeight
            }, 1000);
          },
          components: {
            QuestionSnippet: 'pr-questionsnippet',
            QuestionDisplay: 'pr-questiondisplay',
          }
        };
        pr_products.push(pr_product_question);
      }
    }

    if (pr_products.length > 0 && POWERREVIEWS && typeof Drupal.settings.power_reviews !== 'undefined') {
      POWERREVIEWS.display.render(pr_products);
    }
    $('div.reviews').find('div#pr-questionsnippet').appendTo('div.reviews');
  });

  $(document).on('anchor-review', function() {
    $('.js-pr-rating-tab').trigger('click');
    if (Drupal.settings.power_reviews.show_ask_question) {
      $('html, body').animate({
        'scrollTop': site.client.isMobile ? $('.tabs-nav').offset().top : $('.tabs-nav').offset().top - 150
      }, 1000);
    } else {
      $('html, body').animate({
        'scrollTop': site.client.isMobile ? $('#pr-review-display').offset().top : $('#pr-review-display').offset().top - 150
      }, 1000);
    }
  });

  $(document).on('write-review', function() {
    if (typeof $('.module-spp-detail').data('productid') !== 'undefined' || typeof $('.spp-product').data('productid') !== 'undefined') {
      var product_id = $('.module-spp-detail').data('productid') || $('.spp-product').data('productid');
      var product_base_id = product_id.substring(4);
      var page_id = Drupal.settings.power_reviews.page_id || product_base_id;
      var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?pr_page_id=' + page_id;
      location.href = review_wrapper_url;
    }
  });

  $(document).ready(function() {
    var hash = location.hash;
    $(document).trigger('product.init-pr');
    if (hash.indexOf('write_review') >= 0) {
      $(document).trigger('write-review');
    } else if (hash.indexOf('review') >= 0) {
      $(document).trigger('anchor-review');
    }
  });

  $('ul.tabs-nav li').click(function() {
    var tab_id = $(this).attr('data-tab');
    $('.tabs-content').hide();
    $('ul.tabs-nav li').removeClass('tab-active');
    $('.' + tab_id).show();
    $(this).addClass('tab-active');
  });

  // MTA-210 Track thumbs up rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-210 Track thumbs down  rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-210 Track flagging reviews in review page
  $(document).on('click', '.pr-rd-flag-review-container .pr-underline, .pr-flag-review .pr-flag-review-btn-submit', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'flag this review'
      };
      site.track.rateReviews(obj);
    }
  });

  function powerReviewsAnalyticsTagging() {
    // MTA-210 Analytics enhancements for read/write reviews Starts
    if (drupalPRSettings) {
      // Track sort option in reviews page
      $('#pr-rd-sort-by').once('js-review-sort').on('change', function() {
        if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
          var obj = {
            sort_option: $('option:selected', this).text()
          };
          site.track.sortReviews(obj);
        }
      });

      // Tracking search action in review section
      $('.pr-rd-search-reviews-icon-button').once().on('click', function() {
        if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
          var searchTerm = $(this).siblings(':input').attr('value');
          if (searchTerm && searchTerm !== '') {
            var obj = {
              search_term: searchTerm
            };
            site.track.searchReviews(obj);
          }
        }
      });

      // Tracking filter action in review section
      $('.pr-multiselect').once('js-multi-select').each(function() {
        var selectElement = $(this).find('.pr-multiselect-options li');
        selectElement.on('click', function() {
          var $checkboxElem = $(this);
          if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && !($checkboxElem.attr('data-selected') === 'selected')) {
            $checkboxElem.attr('data-selected', 'selected');
            var selectedFilter = selectElement.filter('[data-selected="selected"]');
            var appliedFiltersArray = [];
            var filter_type = '';
            var filter_type_html = selectElement.closest('.pr-multiselect-options').siblings('.pr-multiselect-button').find('span:not(.pr-multiselect-count)');
            filter_type_html.each(function() {
              var filterText = $(this).text();
              if (!!filterText && filterText.trim() !== '' && filterText.trim() !== '&nbsp;') {
                filter_type = filterText.trim();
              }
            });
            selectedFilter.each(function() {
              appliedFiltersArray.push($(this).text());
            });
            var obj = {
              sort_option: filter_type + ' | ' + appliedFiltersArray.join(', ')
            };
            site.track.filterReviews(obj);
          } else if ($checkboxElem.attr('data-selected') === 'selected') {
            $checkboxElem.attr('data-selected', 'deselected');
          }
        });
      });
    }
    // MTA-210 Analytics enhancements for read/write reviews Ends
  }

  Drupal.behaviors.powerReviews = {
    attach: function(context) {
      var sppDetails = $('.module-spp-detail', context);

      if (POWERREVIEWS && typeof Drupal.settings.power_reviews !== 'undefined') {
        $('#BVRRContainer').attr('id', 'pr-review-display');
        $('#BVRR_Container').attr('id', 'pr-review-display');
        $('#BVRRSummaryContainer').attr('id', 'pr-spp-snippet');
        $('.tabs-nav').before('<div class="tabs-nav-sticky"></div>');
        $('.mpp-box, .product').addClass('js-product-pr');
        if ($(sppDetails).length) {
          $('.reviews', $('.js-product-pr')).empty().append('<div id="pr-questionsnippet"></div>');
        } else {
          $('.reviews', $('.js-product-pr')).empty();
        }
      }
    }
  };
  if (jQuery(window).width() <= 767) {
    function sticktothetop() {
      var top = 0;
      var window_top = $(window).scrollTop();
      var navSticky = $('.tabs-nav-sticky');
      if (navSticky.length > 0) {
        top = navSticky.offset().top;
      }
      if (window_top > top) {
        $('.tabs-nav').addClass('stick');
        navSticky.height($('.tabs-nav').outerHeight());
      } else {
        $('.tabs-nav').removeClass('stick');
        navSticky.height(0);
      }
    }
    $(function() {
      $(window).scroll(sticktothetop);
    });
  }
})(jQuery);
